import Swiper from "swiper/bundle";
import { Navigation, Pagination } from "swiper/modules";

const swiper = new Swiper(".opinions-section__slider", {
  modules: [Navigation, Pagination],
  direction: "horizontal",
  loop: true,
  slidesPerView: 1,
  spaceBetween: 24,

  navigation: {
    nextEl: ".opinions-next-btn",
    prevEl: ".opinions-prev-btn",
  },
  pagination: {
    el: ".opinions-section__pagination",
  },
  breakpoints: {
    900: {
      slidesPerView: 2,
    },
  },
});

const opinionsContent = document.querySelectorAll(
  ".opinions-section__slider__item__content"
);

if (opinionsContent.length > 0) {
  opinionsContent.forEach((item) => {
    const content = item.querySelector("p");
    const height = content.offsetHeight;
    const moreBtnWrapper = item.querySelector(
      ".opinions-section__slider__item__content__more"
    );
    if (height > 120) {
      item.classList.add("collapsed");
      moreBtnWrapper.style.display = "block";
      const btn = moreBtnWrapper.querySelector(
        ".opinions-section__slider__item__content__more-btn"
      );
      btn.addEventListener("click", () => {
        if (item.classList.contains("collapsed")) {
          item.classList.remove("collapsed");
        } else {
          item.classList.add("collapsed");
        }
      });
    }
  });
}
